
import { Component, Vue } from 'vue-property-decorator';

@Component({})

export default class DashboardRestrictedVue extends Vue {
  private profile: any = this.$auth0.user

  private handleLoginEvent(data: any) {
    this.profile = data.profile
  }
}
